<template>
  <v-combobox
    v-if="visible"
    v-model="value"
    v-model:search="search"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    :items="useSafeValue(items, [])"
    :item-value="itemValue"
    :item-title="itemTitle"
    :hide-no-data="false"
  >
    <template #append-inner>
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>
    <template #no-data>
      <v-list-item>
        <v-list-item-title>
          <span>No results matching </span>
          <strong>"{{ search }}".</strong>
          <span>Press <kbd>enter</kbd> to create a new one</span>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useSafeValue } from '@matterific/utils';
import { useMatterificField } from '#imports';
import { get, find } from 'lodash-es';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldSelect',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true },
    // ----------------
    items: { type: [Array, Function], required: true },
    itemValue: { type: String, default: 'value' },
    itemTitle: { type: String, default: 'text' }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );
    const key = props.itemValue || 'value';

    const safeValue = computed({
      get: () => {
        const val = value.value;
        const safeValue = find(props.items, [key, val]) || val;
        return safeValue;
      },
      set: (val) => {
        const safeValue = get(val, key, val);
        value.value = safeValue;
      }
    });

    return {
      meta,
      errors,
      handleBlur,
      validate,
      value: safeValue,
      useSafeValue,
      hasErrors
    };
  },
  data() {
    return {
      search: ''
    };
  }
});
</script>
